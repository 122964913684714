<template>
	<PopupLayer>
		<template
			v-slot:body
		>
			<div
				class="full-height flex-column justify-space-between bg-white"
			>
				<div
					class="under-line justify-space-between items-center pa-10-20 "
				>
					<h6>대리점 대량 등록</h6>
					<button
						@click="$emit('cancel')"
					><mdicon name="close-circle" /></button>
				</div>
				<div
					class="full-height flex-column pa-20 overflow-hidden"
				>
					<div class="justify-space-between items-center mb-10">
						<select
							v-model="item_multi.agency_upper"
							class="input-box-inline"
						>
							<option value="">상위 영업점 선택</option>
							<option
								v-for="(upper, u_index) in items_upper"
								:key="'upper_' + u_index"
								:value="upper.uid"
							>{{ upper.agency_name }}</option>
						</select>
						<select
							v-model="item_multi.sales_type"
							class="input-box-inline"
						>
							<option value="">구분 선택</option>
							<option value="1">지갑</option>
							<option value="10">수기</option>
						</select>
						<label class="flex-1 box pa-5-10">
							<input type="file" @change="setFile" class="hidden" accept=".xls,.xlsx" />
							<template
								v-if="file"
							>
								{{ file.name}}
							</template>
							<template
								v-else
							>
								파일을 선택하세요
							</template>
						</label>
						<button
							class="btn-inline btn-primary"
							:disabled="!file.name"

							@click="postJoin"
						>조회</button>
					</div>

					<div class="full-height overflow-y-auto">
						<table
							v-if="account_list.length > 0"
							class="table table-even"
						>
							<colgroup>
								<col width="40px" />
								<col width="180px" />
								<col width="150px" />
								<col width="150px" />
								<col width="150px" />
								<col width="150px" />
								<col width="auto" />
							</colgroup>
							<thead>
							<tr>
								<th><input v-model="item_all" type="checkbox" /></th>
								<th>{{ account_list[0].B}}</th>
								<th>{{ account_list[0].C}}</th>
								<th>{{ account_list[0].D}}</th>
								<th>{{ account_list[0].E}}</th>
								<th>{{ account_list[0].F}}</th>
								<th>아이디</th>
								<th>비고</th>
							</tr>
							</thead>
							<tbody
								v-for="(item, index) in account_list"
								:key="'item_' + index"
							>
							<tr
								v-if="index > 0"
								:class="item.color"
							>
								<td><input
									v-if="item.is_all"
									v-model="item.is_all" type="checkbox" :disabled="item.is_disabled" :checked="item.is_all"
								/></td>
								<td class="td-left">{{ item.B }}</td>
								<td>{{ item.C }}</td>
								<td>{{ item.D }}</td>
								<td>{{ item.E }}</td>
								<td>{{ item.F }}</td>
								<td>{{ item.account_id }}</td>
								<td>{{ item.info }}</td>
							</tr>
							</tbody>
						</table>
						<Empty
							v-else
						></Empty>
					</div>
				</div>
				<div class="mt-10 pa-20 top-line text-center">
					<button
						@click="postAccount"
						class="btn-inline btn-primary pa-10-20 radius-20"
						:disabled="is_disabled"
					>선택 등록</button>
				</div>
			</div>
		</template>
	</PopupLayer>
</template>
<script>
import PopupLayer from "../Layout/PopupLayer";
import Empty from "../Layout/Empty";
export default {
	props: ['user']
	, components: {Empty, PopupLayer}
	, data: function(){
		return {
			item_multi: {
				agency_upper: ''
				, sales_type: process.env.VUE_APP_TYPE == 'payment' ? 10 : 1
			}
			, items_upper: []
			, file: ''
			, items_file: []
			, items_account: []
			, item_all: true
		}
	}
	, computed: {
		account_list: function(){
			let numbers = []

			return this.items_file.filter( (item, index) => {
				if(item.A && index != 1) {
					let item_phone_number = item.F?.replaceAll('-', '')
					let item_shop_name = item.D
					item.is_phone = true
					item.info = ''
					item.is_all = true

					for(let i = 0; i < this.items_account.length; i++){
						let account = this.items_account[i]
						if(item_phone_number == account.account_phone_number && item_shop_name == account.shop_name && account.account_id){
							item.is_account = true
							item.is_all = false
							item.info = '이미 등록된 계정 정보입니다.'
							item.color = 'bg-success'
							item.is_phone = false
							item.account_id = account.account_id
							break
						}else if(item_phone_number == account.account_phone_number){
							if(account.account_type != 'A001003'){
								item.is_account = true
								item.is_all = false
								item.info = '등록 불가능한 상점 정보입니다'
								item.color = 'bg-red'
								item.is_phone = false
								break
							}else{
								item.is_all = this.item_all
								item.info = '하위 상점으로 등록됩니다.'
								item.color = 'bg-orange'
								item.account_id = account.account_id
							}
						}else{
							item.is_all = this.item_all
						}
					}

					if (item.F?.indexOf('010-') > -1) {

						numbers.filter((number) => {
							if (number == item.F) {
								item.info = '중복된 연락처입니다.'
								item.color = 'bg-orange'
							}
						})
						if (item.is_phone) {
							numbers.push(item.F)
						}
					} else {
						item.is_all = false
						item.info = '사용 불가능한 연락처입니다.'
						item.color = 'bg-red'
						item.is_phone = false
					}

					if (!item.is_phone) {
						item.is_disabled = true
					}

					return item
				}
			})
		}
		, items_account_request: function(){
			let t = []
			this.items_file.filter( (item, index) => {
				if(index > 0 && item.A && item.is_all){
					let data = {
						shop_summary: item.B
						, shop_sort: item.C
						, shop_name: item.D
						, account_name: item.E
						, shop_tell: item.F
					}
					t.push(data)
				}
			})
			return t
		}
		, franchisee_list: function(){
			return this.items.filter( (item) => {

				if(item.virtual_account){
					item.virtual_account_name = item.virtual_account
				}else{
					item.virtual_account_name = '미발급'
				}
				return item
			})
		}

		, is_disabled: function(){
			let t = true
			this.account_list.filter( ( item ) => {
				if(item.is_all){
					t = false
				}
			})
			return t
		}
	}
	, methods: {
		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'admin/getDistributorList'
				})

				if(result.success){
					this.items_upper = result.data.result
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postJoin: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: 'admin/postFranchiseeExcel'
					, data: {
						agency_upper: this.item_multi.agency_upper
						, file: this.file
					}
					, multipart: true
				})

				if(result.success){
					this.items_file = result.data.file_data
					this.items_account = result.data.account_data
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, setFile: function(e){

			let file = e.target.files[0]

			this.file = file
		}
		, postAccount: async function(){

			this.$bus.$emit('on', true)
			try{
				const result = await this.$request.init({
					method: 'post'
					,url: 'admin/postFranchiseeAccount'
					,data: {
						agency_upper: this.item_multi.agency_upper
						, sales_type: this.item_multi.sales_type
						, list: JSON.stringify(this.items_account_request)
					}
				})

				if(result.success){
					await this.postJoin()
					this.$bus.$emit('notify', { type: 'success', message: result.message})
					this.$emit('click')
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	, created() {
		this.getData()
	}
	, watch: {
		item_all: {
			handler: function(call){
				this.items_file = this.items_file.filter( (item) => {
					this.$set(item, 'is_all', call)
					return item
				})
			}
		}
	}
}
</script>