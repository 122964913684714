<template>
	<div
		class="full-height flex-column"
	>
		<Search
			:search="search"
			:option="search_option"
			:is_item="true"

			@change="getSearch"
			@click="getSearch"
			@toExcel="toExcel"
			@toItem="toItem"
		>
			<select
				v-if="user.role == codes.type_code_admin"
				slot="add"
				v-model="search.agency_type"
				class="pa-5-10 mr-10"

				@change="search.agency_upper = ''; getSearch(1)"
			>
				<option value="">영업단 구분</option>
				<template
					v-for="(code, index) in codes.A001.items"
				>
				<option
					v-if="code.code_index > 1 && code.code_index <4"
					:key="code.total_code + '_' + index"
					:value="code.total_code"
				>{{ code.code_name }}</option>
				</template>
			</select>
			<select

				v-if="search.agency_type == ''"
				slot="add"
				v-model="search.agency_upper"
				class="pa-5-10 mr-10"

				@change="getSearch(1)"
			>
				<option value="">총판 구분</option>
				<template
					v-for="(upper, index) in items_upper"
				>
					<option
						v-if="upper.agency_type == 'A001002'"
						:key="'upper_' + index"
						:value="upper.uid"
					>{{ upper.agency_name }}</option>
				</template>
			</select>

			<button
				v-if="false"
				slot="last"
				class="btn-inline btn-identify mr-10"
				@click="toMultiAgency"
			>대량 등록</button>
			<button
				v-if="false"
				slot="last"
				class="btn-inline btn-identify mr-10"
				@click="toExcel2"
			>대량 등록 양식</button>
		</Search>

		<div
			class="mt-10 bg-white pa-10 full-height overflow-y-auto"
		>
			<div v-if="items.length > 0">
				<table class="table table-even">
					<colgroup>
						<col width="40px" />
						<col width="100px" />
						<col width="auto" />

						<col width="auto" />
						<col width="auto" />
						<col width="auto" />
						<col width="auto" />
						<col width="auto" />

						<col width="150px" />
						<col width="100px" />
					</colgroup>
					<thead>
					<tr>
						<th>
							<input
								type="checkbox"
							/>
						</th>
						<th>소속</th>
						<th>구분</th>
						<th>아이디</th>

						<th>상호명</th>
						<th>서비스명</th>
						<th>연락처</th>
						<th>가입일</th>
						<th>계정 사용 여부</th>
						<th v-if="false">상품 판매 여부</th>
						<th>상세정보</th>
					</tr>
					</thead>
					<tbody>
					<tr
						v-for="(item, u_index) in item_list"
						:key="'item_' + u_index"
						:class="{ 'bg-select': item.uid == item_new.uid }"
					>
						<td>
							<input
								type="checkbox"
							/>
						</td>
						<td>{{ item.agency_upper_name }}</td>
						<td>{{ item.agency_type_name }}</td>
						<td>{{ item.account_id }}</td>
						<td>{{ item.account_name }}</td>
						<td>{{ item.shop_name }}</td>
						<td>{{ item.account_phone_number }}</td>
						<td>{{ item.wDate }}</td>
						<td
							class="full-height"
						>
							<div
								class=" flex-row justify-center"
							>
								<v-icon

									class="pa-5"
									:class="item.account_status == 1 ? 'bg-green color-white' : 'btn-default' "
									@click="item.account_status = 1; update(item)"
								>mdi mdi-account-check</v-icon>
								<v-icon

									class="pa-5 "
									:class="item.account_status != 1 ? 'bg-red color-white' : 'btn-default' "
									@click="item.account_status = 0; update(item)"
								>mdi mdi-account-off</v-icon>

								<v-icon
									v-show="false"
									class="pa-5 bg-red color-white ml-10"
									@click="confirmDelete(item)"
								>mdi mdi-delete-forever</v-icon>
							</div>
						</td>
						<td
							v-if="false"
							class="full-height"
						>
							<div
								class=" flex-row justify-center"
							>
								<v-icon

									class="pa-5"
									:class="item.sales_status == 1 ? 'bg-green color-white' : 'btn-default' "
									@click="item.sales_status = 1; update(item)"
								>mdi mdi-cart</v-icon>
								<v-icon

									class="pa-5 "
									:class="item.sales_status != 1 ? 'bg-red color-white' : 'btn-default' "
									@click="item.sales_status = 0; update(item)"
								>mdi mdi-cart-off</v-icon>
							</div>
						</td>
						<td>
							<button

								@click="toDetail(item)"
							>
								<v-icon
									v-if="item.uid == item_new.uid"
									class="color-red"
								>mdi mdi-close-box-outline</v-icon>
								<v-icon
									v-else
									class="color-icon mdi mdi-arrow-right-bold-box-outline"
								/>
							</button>
						</td>
					</tr>
					</tbody>
				</table>

				<Pagination
					:program="program"
					:align="'center'"
					:options="search"

					class="mt-auto"
					@click="getSearch"
				></Pagination>
			</div>

			<Empty
				v-else
			></Empty>
		</div>

		<Excel
			v-if="is_excel"
			:excel_data="excel_data"
			:date="date"

			@close="is_excel = false"
		></Excel>

		<Excel
			v-if="is_excel2"
			:excel_data="excel_data2"
			:date="date"

			@close="is_excel2 = false"
		></Excel>

		<AgencyMulti
			v-if="is_multi"
			:user="user"

			@click="getData();"
			@cancel="is_multi = false"
		></AgencyMulti>
	</div>
</template>

<script>

import Pagination from "../../components/Pagination";
import Search from "../Layout/Search";
import Excel from "../../components/Excel";
import Empty from "@/view/Layout/Empty";
import AgencyMulti from "./AgencyMulti";

export default {
	name: 'AgencyList'
	, components: {AgencyMulti, Empty, Excel, Search, Pagination}
	, props: ['Axios', 'rules', 'TOKEN', 'date', 'codes', 'user']
	, data: function (){
		return {
			program: {
				name: '영업점 목록'
				,top: true
				,title: true
			}
			,options: {

			}
			,search: this.$storage.init({
				page: 1
				, search_type: 'account_id'
				, search_value: ''
				, list_cnt: 10
				, account_status: ''
				, agency_type: ''
				, agency_upper: ''
				, shop_summary: ''
			})
			,search_option:{

				is_item: false
				,is_excel: true
				,is_cnt: true
				,cnt: 0
				,tCnt: 0
				,search_type: [
					{ name: '아이디', column: 'account_id'}
					, { name: '상호명', column: 'account_name'}
					, { name: '서비스명', column: 'seller_info.shop_name'}
					, { name: '연락처', column: 'account_phone_number'}
				]
				,select: [
					{ name: '사용 여부', column: 'account_status', items: [
							{ name: '사용', column: '1'}
							,{ name: '미사용', column: '0'}
						]
					}
				]
			}
			,items: [

			]
			,item_new: {

			}
			,is_item: false
			,is_excel: false
			, is_excel2: false
			,excel_data: {
				name: '영업점 목록'
				,header: [
					{ key: 0, name: '구분', column: 'agency_type_name'}
					,{ key: 0, name: '소속', column: 'agency_upper_name'}
					,{ key: 0, name: '아이디', column: 'account_id'}
					,{ key: 0, name: '상호명', column: 'account_name'}
					,{ key: 0, name: '서비스명', column: 'shop_name'}
					,{ key: 0, name: '연락처', column: 'account_phone_number'}
					,{ key: 0, name: '가입일', column: 'wDate'}
					,{ key: 0, name: '사용여부', column: 'account_status_name'}
				]
				,content: null
			}
			,excel_data2: {
				name: '대량 등록 양식'
				,header: [
					{ key: 0, name: '표기 구분', column: 'shop_summary'}
					,{ key: 0, name: '상호명', column: 'account_name'}
					,{ key: 0, name: '휴대폰 번호', column: 'account_phone_number'}
				]
				,content: [
					{
						shop_summary: '지사, 총판, 대리점, 가맹점 중 택1'
						, shop_sort: '가맹점 번호'
						, shop_name: '가맹점 상호 (중복 불가)'
						, account_name: '대표자 상호명'
						, account_phone_number: '[010-1234-5678] 중복시 동일 계정의 하위 상점으로 등록'
					}
				]
			}
			, items_upper: []
			, is_multi: false
		}
	}
	,computed: {
		item_list: function (){

			return this.items.filter((item) => {

				switch (item.account_status){
					case '0':
						item.account_status_name = '승인대기'
						break;
					case '1':
						item.account_status_name = '승인완료'
						break;
					case '2':
						item.account_status_name = '사용불가'
						break;
				}
				return item
			})
		}
		,btn_name: function (){
			if(this.item_new.uid){
				return '저장'
			}else{
				return '신규 등록'
			}
		}
	}
	,methods: {
		getData: async function(){
			this.$bus.$emit('on', true)
			try{
				const result = await this.$request.init({
					method: 'get'
					, url: 'management/getAgencyList'
					, data: this.search
				})

				if(result.success){
					this.items = result.data.result
					this.$set(this.search, 'total_count', result.data.tCnt)
					this.search_option.tCnt = result.data.tCnt
					this.search_option.cnt = result.data.cnt
					this.$storage.setQuery(this.search)
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,save: async function(){
			this.$bus.$emit('on', true)
			try{
				const result = await this.$request.init({
					method: 'post'
					,url: 'management/postSeller'
					,data: this.item_new
				})

				if(result.success){
					await this.getSearch()
					this.clear_item()
					this.$bus.$emit('notify', { type: 'success', message: result.message })
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: '통신 오류' })
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,update: async function(item){
			this.$bus.$emit('on', true)
			try{
				const result = await this.$request.init({
					method: 'post'
					,url: 'management/putAgencyUpdate'
					,data: item
				})

				if(result.success){
					this.clear_item()
					this.$bus.$emit('notify', { type: 'success', message: result.message })
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: '통신 오류' })
			}finally {
				await this.getSearch()
				this.$bus.$emit('on', false)
			}
		}
		,toDetail: function (item){

			this.$bus.$emit('to', { name: 'AgencyDetail', params: {idx: item.shop_uid}})
		}
		,clear_item: function(){
			this.item_new = {
				admin_level: 0
				,admin_type: 'agency'
			}
			this.is_item = false
		}
		,confirmDelete: function(item){
			if(confirm("삭제하시겠습니까?")){
				this.deleteItem(item)
			}
		}
		,deleteItem: async  function(item){
			this.$bus.$emit('on', true)
			try{
				const result = await this.$request.init({
					method: 'post'
					,url: 'management/postAgencyDelete'
					,data: item
				})

				if(result.success){
					await this.getData()
					this.$bus.$emit('notify', { type: 'success', message: result.message })
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: '통신 오류' })
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,toExcel: function(){
			this.excel_data.content = this.items
			this.is_excel = true
		}
		,toExcel2: function(){
			this.is_excel2 = true
		}
		,toItem: function (){
			this.$emit('push', { name: 'AgencyItem', params: { type: this.$route.params.type}})
		}
		,getSearch: function(page){
			if(page){
				this.search.page = page
			}

			this.getData()
		}
		, getDistributorList: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'management/getAgencyUpper'
					,data: {
						agency_type: 'A001003'
					}
				})
				if(result.success){
					this.items_upper = result.data
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, toMultiAgency: function(){
			this.is_multi = true
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
		this.clear_item()
		this.getData()
		this.getDistributorList()
	}
	,watch: {
		'search.page': {
			handler: function(){
				this.getSearch()
			}
		}
	}
}
</script>